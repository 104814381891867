<template>
  <div class="border">
    <div class="pr-3">
      <v-text-field
        outlined
        rounded
        dense
        v-model="search"
        label="Search"
        :append-icon="icons.mdiMagnify"
        :prepend-icon="icons.mdiFilterVariant"
        @click:prepend="handleFilter"
      />
    </div>
    <div class="overflow-y-auto px-2" style="height: 600px;top: -20px;position: relative;">
      <v-progress-linear :style="(conversations_loading ? 'opacity:1;': 'opacity:0;')" indeterminate height="3"/>
      <v-expansion-panels accordion class="ml-n1 panels" v-model="panel" multiple>
        <v-expansion-panel v-for="(type, key) in types" :key="key">
          <v-expansion-panel-header>
            <h4>{{ type.title }}</h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div>
              <v-list style="max-height: 700px;" class="overflow-y-auto" color="rgba(0,0,0,0)">
                <v-list-item-group>
                  <template v-if="conversations && typeof conversations[type.id] !== 'undefined'" v-for="(item, index) in conversations[type.id]">
                    <v-list-item @click="selectChat(item)" three-line :key="item.id">
                      <v-list-item-avatar>
                        <v-img src="/avatar.png"></v-img>
                      </v-list-item-avatar>
                      <template>
                        <v-list-item-content>
                          <v-list-item-title>{{ item.contact.first_name }} {{ item.contact.last_name }}</v-list-item-title>
                          <v-list-item-subtitle v-if="item.last_message && typeof item.last_message.text !== 'undefined'">{{item.last_message.text}}</v-list-item-subtitle>
                          <v-list-item-subtitle v-else-if="item.last_message && typeof item.last_message.media !== 'undefined'">
                            <span class="pr-1"><v-icon small>{{ icons.mdiFile }}</v-icon></span>
                            <span>Shared file</span>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle class="text-right">
                            {{ $moment(item.updated_at).format('lll') }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-list-item>

                    <v-divider v-if="index < conversations[type.id].length - 1" />
                  </template>
                </v-list-item-group>
              </v-list>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import io from "socket.io-client";

export default {
  name: "SideBar",
  props: {
    icons: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      conversations_loading: false,
      selected_chat: null,
      search: null,
      conversations: {
        new_conversations: [],
        ongoing_conversations: [],
        finished_conversations: []
      },
      index_conversations: {
        new_conversations: null,
        ongoing_conversations: null,
        finished_conversations: null
      },
      types: [
        {
          title: 'New Conversations',
          id: 'new_conversations'
        },
        {
          title: 'Ongoing Conversations',
          id: 'ongoing_conversations'
        },
        {
          title: 'Last 30 Finished Conversations',
          id: 'finished_conversations'
        },
      ],
      panel: [0, 1, 2],
      errors: null
    }
  },
  created() {
    this.getConversations()
    this.socketConnect();
  },
  watch: {
    search() {
      this.getConversations()
    }
  },
  methods: {
    getConversations() {
      this.errors = null
      this.conversations_loading = true
      this.$http({
        method: 'get',
        url: `/v1/conversations?search=${this.search}`,
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
          Authorization: `${this.$store.state.access.token_type} ${this.$store.state.access.token}`,
        }
      }).then(response => {
        this.conversations = {
          new_conversations: response.data.new_conversations,
          ongoing_conversations: response.data.ongoing_conversations,
          finished_conversations: response.data.finished_conversations,
        }
      }).catch(err => {
        this.errors = err.response.data
      }).finally(() => {
        this.conversations_loading = false
      })
    },
    selectChat(item) {
      this.selected_chat = item
      this.$emit('selectChat', item)
    },
    socketConnect() {
      this.socketDisconnect()
      this.socket = io(process.env.VUE_APP_SOCKET)
      this.socket.on('ch-sidebar', message => {
        this.getConversations()
      })
    },
    socketDisconnect() {
      if(this.socket){
        this.socket.disconnect()
      }
    },
    handleFilter() {
    },
  }
}
</script>

<style scoped>
.v-application.theme--light .v-expansion-panel::before, .v-application.theme--dark .v-expansion-panel::before {
  box-shadow: none !important;
}

.v-application.theme--light .v-expansion-panel, .v-application.theme--dark .v-expansion-panel {
  box-shadow: none !important;
}

.panels {
  margin-top: 1px;
  box-shadow: none !important;
}
</style>
