<template>
  <div>
    <div v-if="selected_chat">
      <v-banner v-if="debug" sticky>
        <v-subheader>Offset Top {{ offsetTop }} Page: {{ page }}</v-subheader>
      </v-banner>
      <div :class="(public ? 'px-1' : '')">
        <v-card :loading="loading" :key="selected_chat.token" outlined class="my-2">
          <v-row class="pa-2">
            <v-col cols="12" md="6" class="text-center text-md-left">
              <div v-if="searchToggle">
                <v-text-field
                  dense
                  outlined
                  hide-details
                  v-model="search"
                  label="Search"
                />
              </div>
              <div v-else class="px-1 pt-1">
                <v-chip class="mr-1 font-weight-bold transparent">{{ selected_chat.contact.first_name }} {{ selected_chat.contact.last_name }}</v-chip>
                <v-chip label>{{ selected_chat.contact.msisdn }}</v-chip>
              </div>
            </v-col>
            <v-col cols="12" md="6" class="text-center text-md-right">
              <div>
                <v-chip style="margin-top: 3px;" v-if="this.selected_chat.status !=='ongoing'">Expiration: {{ $moment(this.selected_chat.expiration_at).format('lll') }}</v-chip>
                <v-btn v-if="false" class="mx-3" @click="searchToggle = !searchToggle" icon>
                  <v-icon v-if="!searchToggle">{{ icons.mdiMagnify }}</v-icon>
                  <v-icon v-if="searchToggle">{{ icons.mdiClose }}</v-icon>
                </v-btn>
                <v-btn
                  v-if="selected_chat.status === 'ongoing' && selected_chat.taken_user"
                  dark
                  depressed
                  :disabled="leave_conversation_loading"
                  :loading="leave_conversation_loading"
                  @click="leave_dialog = true"
                >
                  Leave Conversation
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </div>
      <div @scroll="checkScrollBottom" id="messages-playground" ref="messages-playground" class="messages-playground py-3 px-5" :class="(public ? 'messages-playground-height-public': 'messages-playground-height')">
        <div v-scroll:#messages-playground="onScroll" v-for="(row, key) in messages" :key="key">
          <AgentMessage v-if="row.user" :row="row"/>
          <UserMessage v-if="row.contact" :row="row"/>
        </div>
        <div v-if="show_scroll_down_button" style="bottom: 100px;margin: auto;right: 2%;" class="position-absolute text-center">
          <v-chip
            color="black"
            dark
            class="mb-1 elevation-5"
            label
          >
            New Message
          </v-chip>
          <br>
          <v-btn
            color="black"
            dark
            small
            class="elevation-5"
            @click="scrollToLastMessage"
            fab
          >
            <v-icon>{{ icons.mdiArrowDown }}</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="px-2" v-if="['new','finished'].includes(selected_chat.status)">
        <v-btn v-if="selected_chat.taken_user && selected_chat.taken_user.id !== $store.state.user.id" disabled color="primary" class="mt-3" block depressed>Taken by {{selected_chat.taken_user.full_name}} </v-btn>
        <v-btn v-else-if="!expired" :disabled="take_control_loading" :loading="take_control_loading" @click="take_control_dialog = true" color="primary" class="mt-3" block depressed>Take Control</v-btn>
        <v-btn v-else disabled color="primary" class="mt-3" block depressed>Time exceeded</v-btn>
      </div>
      <form class="px-2" @submit.prevent="sendMessage" v-else-if="selected_chat.status === 'ongoing' && !expired">
        <v-text-field
          :loading="send_loading"
          :disabled="send_loading"
          :dir="Dir"
          v-model="message"
          :append-outer-icon="icons.mdiSend"
          outlined
          rounded
          hide-details
          label="Message"
          type="text"
          class="pa-0 mt-3"
          ref="message_text"
          autofocus
          @click:append-outer="sendMessage"
        ></v-text-field>
      </form>
    </div>
    <div v-else>
      <div class="text-center agent-control-chat">
        <v-img height="300" width="400" class="ma-auto" contain src="/chat-placeholder.png"/>
        <v-progress-circular indeterminate v-if="public"/>
        <h3 class="text-center mt-5">
          {{ placeholder }}
        </h3>
      </div>
    </div>
    <v-dialog
      v-model="leave_dialog"
      width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          Are you sure?
        </v-card-title>

        <v-card-text>
          You will leave the conversation
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="leave_dialog = false"
          >
            No
          </v-btn>

          <v-btn
            color="#e90000"
            text
            @click="leaveConversation"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="take_control_dialog"
      width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          Are you sure?
        </v-card-title>

        <v-card-text>
          You will take the control for this conversation
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="take_control_dialog = false"
          >
            No
          </v-btn>

          <v-btn
            color="#e90000"
            text
            @click="takeControl"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AgentMessage from "@/views/AgentsControl/AgentMessage"
import UserMessage from "@/views/AgentsControl/UserMessage"
import io from 'socket.io-client'
import _ from 'lodash'

export default {
  name: "Conversation",
  props: {
    icons: {
      type: Object,
      default: null,
    },
    prop_selected_chat: {
      type: Object,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    public: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AgentMessage,
    UserMessage
  },
  watch: {
    prop_selected_chat() {
      this.selected_chat = this.prop_selected_chat
    },
    selected_chat() {
      this.message = null
      if (this.selected_chat) {
        this.socketConnect()
        this.page = 1
        this.offsetTop = 0
        this.messages = []
        this.getMessages()
      }
    },
    offsetTop() {
      if (this.offsetTop === 0 && !this.loading && (this.server_pagination && this.page <= this.server_pagination.last_page)) {
        console.log("Reached the top")
        this.page++
        this.getMessages()
      }
    },
  },
  computed: {
    Dir() {
      if (this.message && this.checkUTF8(this.message)) {
        return 'rtl';
      } else {
        return 'ltr'
      }
    },
    expired() {
      if (!this.selected_chat) {
        return false
      }
      let now = this.$moment()
      let expiration = this.$moment(this.selected_chat.expiration_at)
      let duration = this.$moment.duration(expiration.diff(now));
      let hours = duration.asHours()
      return (hours <= 0)
    },
  },
  data() {
    return {
      debug: false,
      offsetTop: 0,
      show_scroll_down_button: false,
      scrolling_bottom: true,
      selected_chat: null,
      page: 1,
      server_pagination: null,
      messages: [],
      send_loading: false,
      leave_conversation_loading: false,
      loading: false,
      take_control_loading: false,
      leave_dialog: false,
      take_control_dialog: false,
      searchToggle: false,
      message: null,
      search: null,
      first_message: null,
      last_message: null,
      scrolling_options: {
        container: '#messages-playground',
        offset: 0,
        duration: 300,
        easing: 'easeInOutCubic',
      }
    }
  },
  methods: {
    checkScrollBottom({target: {scrollTop, clientHeight, scrollHeight}}) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.scrolling_bottom = true
        this.show_scroll_down_button = false
        console.log("Reached the bottom")
      } else {
        this.scrolling_bottom = false
        console.log("left the bottom")
      }
    },
    onScroll(e) {
      this.offsetTop = e.target.scrollTop
    },
    checkUTF8(text) {
      const regExp = /[\u0600-\u06FF]/;
      return regExp.test(text)
    },
    sendMessage() {
      if (!this.message || this.send_loading) {
        return null
      }
      this.send_loading = true
      this.$http({
        method: 'POST',
        url: `/v1/conversations/send-message`,
        data: {
          token: this.selected_chat.token,
          message: this.message
        },
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
          'Transaction-Id': this.generate_transaction(30),
          Authorization: `${this.$store.state.access.token_type} ${this.$store.state.access.token}`,
        }
      }).then(response => {
        let message = response.data.message;
        this.addClientMessage(message, true)
      }).catch(err => {
        this.$store.state.errors = err.response.data
      }).finally(() => {
        this.clearMessage()
        this.send_loading = false
        this.$nextTick(() => this.$refs.message_text.focus())
      })
    },
    addClientMessage(message, force_scroll = false) {
      this.messages = [...this.messages, message]
      this.last_message = _.last(this.messages);
      if (this.scrolling_bottom || force_scroll) {
        this.scrollToLastMessage()
      }
    },
    leaveConversation() {
      this.take_control_dialog = false
      this.leave_dialog = false
      this.leave_conversation_loading = true
      this.$store.state.errors = []
      this.$http({
        method: 'post',
        url: `/v1/conversations/leave-control`,
        data: {
          token: this.selected_chat.token
        },
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
          Authorization: `${this.$store.state.access.token_type} ${this.$store.state.access.token}`,
        }
      }).then(response => {
        this.$emit('updateSideBar')
        if(this.public){
          this.$emit('getChatByToken')
        }else{
          this.selected_chat = null
        }
      }).catch(err => {
        this.$store.state.errors = err.response.data
      }).finally(() => {
        this.leave_conversation_loading = false
        this.socketDisconnect()
      })
    },
    takeControl() {
      this.take_control_dialog = false
      this.leave_dialog = false
      this.take_control_loading = true
      this.$store.state.errors = []
      this.$http({
        method: 'post',
        url: `/v1/conversations/take-control`,
        data: {
          token: this.selected_chat.token
        },
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
          Authorization: `${this.$store.state.access.token_type} ${this.$store.state.access.token}`,
        }
      }).then(response => {
        this.selected_chat = response.data.conversation
        this.$emit('updateSideBar')
      }).catch(err => {
        this.$store.state.errors = err.response.data
      }).finally(() => {
        this.take_control_loading = false
      })
    },
    getMessages() {
      this.loading = true
      this.$store.state.errors = []
      this.$http({
        method: 'GET',
        url: `/v1/conversations/get-messages?token=${this.selected_chat.token}&page=${this.page}&search=${this.search}`,
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
          Authorization: `${this.$store.state.access.token_type} ${this.$store.state.access.token}`,
        }
      }).then(response => {
        this.server_pagination = {
          current_page: response.data.messages.current_page,
          last_page: response.data.messages.last_page,
          total: response.data.messages.total,
        }
        let target = null
        let new_messages = response.data.messages.data
        let current_messages = this.messages
        this.messages = [...current_messages, ...new_messages];
        this.messages = _.sortBy(this.messages, ['created_at'], 'desc');
        this.first_message = _.first(new_messages);
        this.last_message = _.last(this.messages);
        if (this.page === 1) {
          target = `#message-${this.last_message.id}`;
        } else {
          target = `#message-${this.first_message.id}`;
        }
        setTimeout(() => {
          this.scrolling_options.duration = 10
          this.$vuetify.goTo(target, this.scrolling_options)
        }, 10)
      }).catch(err => {
        if (typeof err.response.data !== 'undefined') {
          this.$store.state.errors = err.response.data
        }
      }).finally(() => {
        this.loading = false
      })
    },
    clearMessage() {
      this.message = null
    },
    resetIcon() {
      this.iconIndex = 0
    },
    generate_transaction(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
          charactersLength));
      }
      return result;
    },
    socketConnect() {
      this.socketDisconnect()
      this.socket = io(process.env.VUE_APP_SOCKET)
      this.socket.on(this.selected_chat.token, message => {
        if (message) {
          this.addClientMessage(message)
          this.$emit('updateSideBar')
          this.show_scroll_down_button = true
        }
      })
    },
    socketDisconnect() {
      if (this.socket) {
        this.socket.disconnect()
      }
    },
    scrollToLastMessage() {
      let target = `#message-${this.last_message.id}`;
      setTimeout(() => {
        this.scrolling_options.duration = 10
        this.$vuetify.goTo(target, this.scrolling_options)
      }, 10)
    }
  },
}
</script>

<style scoped>
.messages-playground {
  height: 555px;
  overflow-y: auto;
  border-bottom: 1px solid rgba(227, 227, 227, 0.50);
}

.messages-playground-height {
  height: 555px;
}

.messages-playground-height-public {
  height: 80vh;
}
</style>
