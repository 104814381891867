<template>
  <v-card class="px-5" elevation="1">
    <v-row>
      <v-col cols="12" md="4" class="pl-1 py-5">
        <SideBar ref="sidebar" @selectChat="selectChat" :icons="icons" />
      </v-col>
      <v-col cols="12" md="8">
        <Conversation :placeholder="placeholder" @updateSideBar="triggerUpdateSidebar" :prop_selected_chat="selected_chat" chat="selected_chat" :icons="icons" />
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mdiMagnify, mdiFilterVariant, mdiSend, mdiClose, mdiFile, mdiArrowDown } from '@mdi/js'
import SideBar from "@/views/AgentsControl/SideBar";
import Conversation from "@/views/AgentsControl/Conversation";
export default {
  data: () => ({
    placeholder: 'Select a chat to start messaging',
    selected_chat: null,
    icons: {
      mdiMagnify,
      mdiFilterVariant,
      mdiSend,
      mdiClose,
      mdiFile,
      mdiArrowDown
    },
  }),
  components:{
    SideBar,
    Conversation
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },

  },
  methods: {
    selectChat(item){
      this.selected_chat = item
    },
    triggerUpdateSidebar() {
      this.$refs.sidebar.getConversations()
    },
  },

};
</script>
<style>
.border {
  border-right: 1px solid #e5e5e5;
}
.agent-control-chat {
  padding-top: 120px;
}
</style>
